import About from "../components/About.js";
import Services from "../components/Services.js";
import Contact from "../components/Contact.js";
import Intro from "../components/Intro.js";
import JoinUs from "../components/JoinUs.js";
import MainLayout from "../components/MainLayout.js";
import Nav from "../components/Nav.js";
import React from "react";
import Vision from "../components/Vision.js";
import Team from "../components/Team.js";
import Testimonials from "../components/Testimonials.js";
import LocaleProvider from "../components/LocaleProvider.js";

import "../styles/slides.min.css";

const IndexPage = props => {
    React.useEffect(() => {
        if (!location?.hash) return;
        setTimeout(() => {
            if (location.hash) {
                location.href = location.hash;
            }
        }, 200);
    });
    return (
        <LocaleProvider>
            <MainLayout>
                <Nav />
                <Intro />
                <About />
                <Vision />
                <Services />
                <Testimonials />
                <JoinUs />
                <Team />
                <Contact />
            </MainLayout>
        </LocaleProvider>
    );
};

export default IndexPage;
