import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";

const Video = ({ width, height, src }) => {
    return (
        <iframe
            src={src}
            width={width}
            height={height}
            frameBorder="0"
            allowFullScreen
        />
    );
};

const getWindowWidth = () => {
    return typeof window !== "undefined" ? window.innerWidth : 1024;
};

const Videos = () => {
    const videoStyle = {
        padding: "10px",
        flexShrink: 1
    };
    const videoRatio = 360 / 640;
    const windowWidth = getWindowWidth();
    const videoWidth = Math.min(Math.max(windowWidth / 4, 320), windowWidth);
    const videoHeight = videoWidth * videoRatio;
    return (
        <div className="fromCenter">
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-around"
                }}
            >
                <div style={videoStyle}>
                    <Video
                        src="https://player.vimeo.com/video/775030054"
                        width={videoWidth}
                        height={videoHeight}
                    />
                </div>
            </div>
        </div>
    );
};

const About = () => {
    return (
        <Section
            scrollId="about"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "about"
            }}
        >
            <div className="content">
                <div className="container">
                    <div className="wrap">
                        <div className="fix-10-12 toCenter">
                            <h1 className="ae-1 fromLeft header-11 light">
                                <I18n label="About_Title" />
                            </h1>
                        </div>
                        <div className="fix-11-12">
                            <div className="ae-2 light">
                                <p className="fromCenter">
                                    <I18n label="About_p1a" />
                                    &nbsp;
                                    <a
                                        className="customHover"
                                        href="https://epfl-innovationpark.ch/"
                                        target="_blank"
                                    >
                                        EPFL Innovation Park
                                    </a>
                                    &nbsp;
                                    <I18n label="About_p1b" />
                                    &nbsp;
                                    <a
                                        className="customHover"
                                        href="https://learn.epfl.ch/"
                                        target="_blank"
                                    >
                                        LEARN
                                    </a>
                                    &nbsp;
                                    <I18n label="About_p1c" />
                                </p>
                                <p></p>
                                <p></p>
                                <p className="fromCenter">
                                    <I18n label="About_p2" />
                                </p>
                                <p></p>
                                <p></p>
                                <p className="fromCenter">
                                    <I18n label="About_p3" />
                                </p>
                                <Videos />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default About;
