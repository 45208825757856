import Content from "./Content.js";
import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";

const Item = props => {
    const { imgUrl, label } = props;
    return (
        <div style={{}}>
            <img
                className="ae-3 fromCenter margin-bottom-3 margin-top-5"
                height="100"
                src={imgUrl}
            />
            <div className="fix-45 ae-4">
                <p className="small">
                    <I18n label={label} />
                </p>
            </div>
        </div>
    );
};

const Services = props => {
    return (
        <Section
            scrollId={"services"}
            sectionProps={{
                className: "slide fade whithslide",
                "data-name": "campus"
            }}
        >
            <Content>
                <div className="container">
                    <div className="wrap">
                        <div className="fix-12-12">
                            <h1 className="cropBottom ae-1 fromCenter">
                                <strong>
                                    <I18n label="ServicesTitle" />
                                </strong>
                            </h1>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "space-around",
                                    flexWrap: "wrap",
                                    width: "100%"
                                }}
                            >
                                <Item
                                    imgUrl="static/img/ico_collider_01.png"
                                    label="Services_p1"
                                />
                                <Item
                                    imgUrl="static/img/ico_collider_02.png"
                                    label="Services_p2"
                                />
                                <Item
                                    imgUrl="static/img/ico_collider_03.png"
                                    label="Services_p3"
                                />
                                <Item
                                    imgUrl="static/img/ico_collider_04.png"
                                    label="Services_p4"
                                />
                                <Item
                                    imgUrl="static/img/ico_collider_05.png"
                                    label="Services_p5"
                                />
                                <Item
                                    imgUrl="static/img/ico_collider_06.png"
                                    label="Services_p6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <div
                className="background"
                style={{ backgroundImage: "url(static/img/img-22.jpg)" }}
            ></div>
        </Section>
    );
};

export default Services;
