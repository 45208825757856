import { Carousel } from "react-responsive-carousel";
import "../styles/carousel.css";
import React from "react";
import Section from "./Section.js";
import I18n from "./I18n";

const memberTestimonials = [
    {
        text: "Testimonials_Apelab_text",
        name: "Maria Maria Beltran",
        position: "Testimonials_Apelab_position",
        companyName: "Zoe Immersive (former Apelab)",
        website: "https://zoeimmersive.com"
    },
    {
        text: "Testimonials_WeWent_text",
        name: "Aleskandra Potrykus",
        position: "Testimonials_WeWent_position",
        companyName: "WeWent",
        website: "https://wewent.com"
    },
    {
        text: "Testimonials_Mobsya_text",
        name: "Christophe Barraud",
        position: "Testimonials_Mobsya_position",
        companyName: "Mobsya",
        website: "http://www.mobsya.org"
    },
    {
        text: "Testimonials_Classtime_text",
        name: "Jan Rihak",
        position: "Testimonials_Classtime_position",
        companyName: "Classtime",
        website: "https://www.classtime.com/en/"
    },
    {
        text: "Testimonials_Ubisim_text",
        name: "Gauthier Dubruel",
        position: "Testimonials_Ubisim_position",
        companyName: "UbiSim",
        website: "https://ubisimvr.com"
    },
    {
        text: "Testimonials_Tasbase_text",
        name: "Samuel Portman",
        position: "Testimonials_Tasbase_position",
        companyName: "Taskbase",
        website: "https://www.taskbase.com"
    },
    {
        text: "Testimonials_TEA_text",
        name: "Sarah Schwab",
        position: "Testimonials_TEA_position",
        companyName: "The Experience Accelerator",
        website: "https://theexperienceaccelerator.com"
    },
    {
        text: "Testimonials_DualAcademy_text",
        name: "Omar Luethi",
        position: "Testimonials_DualAcademy_position",
        companyName: "Dual Academy",
        website: "http://www.dual.ac"
    },
    {
        text: "Testimonials_Kiwix_text",
        name: "Stephane Coillet-Matillon ",
        position: "Testimonials_Kiwix_position",
        companyName: "Kiwix",
        website: "https://www.kiwix.org/en/"
    },
    {
        text: "Testimonials_LEDSafari_text",
        name: "Govinda Upadhyay",
        position: "Testimonials_LEDSafari_position",
        companyName: "LEDsafari",
        website: "http://www.ledsafari.com"
    },
    {
        text: "Testimonials_TestWe_text",
        name: "Clément Régnier",
        position: "Testimonials_TestWe_position",
        companyName: "TestWe",
        website: "https://testwe.eu/en/"
    },
    {
        text: "Testimonials_MagmaLearning_text",
        name: "Maxime Gabella",
        position: "Testimonials_MagmaLearning_position",
        companyName: "Magma Learning",
        website: "https://www.magmalearning.com"
    },
    {
        text: "Testimonials_MaxBrain_text",
        name: "Alex Blattmann",
        position: "Testimonials_MaxBrain_position",
        companyName: "MaxBrain",
        website: "https://maxbrain.com/"
    },
    {
        text: "Testimonials_SmartestLearning_text",
        name: "Mehdi Cherif-Zahar",
        position: "Testimonials_SmartestLearning_position",
        companyName: "Smartest Learning",
        website: "https://smartest.io/"
    },
    {
        text: "Testimonials_MobilePractice_text",
        name: "Ben Eddy",
        position: "Testimonials_MobilePractice_position",
        companyName: "Mobile Practice",
        website: "www.mobilepractice.io"
    },
    {
        text: "Testimonials_Elever_text",
        name: "Thomas Hayk",
        position: "Testimonials_Elever_position",
        companyName: "Elever",
        website: "https://elever.ch"
    },
    {
        text: "Testimonials_eSkills_text",
        name: "Annick Monnier Rivkine",
        position: "Testimonials_eSkills_position",
        companyName: "eSkills",
        website: "https://eskills.ch/en/"
    }
];

const Testimonial = ({ testimonial }) => {
    const wrapperStyle = {
        backgroundColor: "#fff",
        color: "#444",
        padding: "40px 60px",
        textAlign: "left"
    };
    const textStyle = {
        fontSize: "1.2em",
        lineHeight: "1.2em"
    };
    const footerStyle = {
        marginTop: "10px"
    };
    const authorStyle = {
        fontSize: "1.2em",
        color: "#ec6675"
    };
    const linkStyle = {
        color: "#444"
    };
    return (
        <div style={wrapperStyle}>
            <div style={textStyle}>
                <I18n label={testimonial.text} />
            </div>
            <div style={footerStyle}>
                <div style={authorStyle}>
                    {testimonial.name}, <I18n label={testimonial.position} />,{" "}
                    {testimonial.companyName}
                </div>
                <div style={linkStyle}>
                    <a href={testimonial.website} target="_blank">
                        {testimonial.website}
                    </a>
                </div>
            </div>
        </div>
    );
};

const Testimonials = () => (
    <Section
        scrollId="testimonials"
        sectionProps={{
            className: "slide whiteSlide",
            "data-name": "about"
        }}
    >
        <div className="content">
            <div className="container">
                <div className="wrap">
                    <div className="fix-10-12 toCenter">
                        <h1 className="ae-1 fromLeft header-11 light">
                            <I18n label="TestimonialsTitle" />
                        </h1>
                    </div>
                    <div className="fix-11-12">
                        <div className="ae-2 light">
                            <Carousel
                                dynamicHeight={true}
                                infiniteLoop={true}
                                interval={6000}
                                showArrows={true}
                                showIndicators={false}
                                showStatus={false}
                                showThumbs={false}
                                autoPlay={true}
                            >
                                {memberTestimonials.map(testimonial => (
                                    <Testimonial
                                        key={testimonial.name}
                                        testimonial={testimonial}
                                    />
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Section>
);

export default Testimonials;
