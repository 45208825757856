import Content from "./Content.js";
import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";

const Vision = props => {
    return (
        <Section
            scrollId="vision"
            sectionProps={{
                className: "slide fade whiteSlide",
                "data-name": "vision"
            }}
        >
            <Content>
                <div className="container">
                    <div className="wrap">
                        <div className="fix-10-12">
                            <h1 className="ae-1">
                                <I18n label="VisionTitle" />
                            </h1>
                            <div className="ae-2">
                                <p>
                                    <I18n label="Vision_p1" />
                                </p>
                                <p></p>
                                <p>
                                    <I18n label="Vision_p2" />
                                </p>
                                <p></p>
                                <p>
                                    <I18n label="Vision_p3" />
                                </p>
                                <p></p>
                            </div>
                            {/*<div className="button play white popupTrigger ae-5 fromCenter margin-top-3" data-popup-id="10-0">
                                <svg><use href="#play"></use></svg>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </Content>
            <div
                className="background"
                style={{ backgroundImage: "url(static/img/img-81.jpg)" }}
            ></div>
        </Section>
    );
};

export default Vision;
