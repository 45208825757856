import _ from "underscore";
import { contactEmail, devEmail, formspreeEndPoint } from "../constants.js";
import Content from "./Content.js";
import React from "react";
import Section from "./Section.js";
import { availableTests } from "../data/testbed.js";
import I18n, { translate } from "./I18n.js";
import LocaleContext from "./LocaleContext.js";

const Languages = {
    DE: "German",
    EN: "English",
    FR: "French"
};

const Reasons = {
    BECOME_MEMBER: "New member request",
    OTHER: "Contact Form Submission"
};
const ReasonLabel = {
    "Contact Form Submission": "Other",
    "New member request": "Become member"
};

const DefaultSubject = Reasons.OTHER;

const ReasonSelector = props => {
    const { setSubject, subject } = props;
    const setOption = ({ option, checked }) => {
        switch (option) {
            case Reasons.BECOME_MEMBER: {
                if (checked) {
                    setSubject(Reasons.BECOME_MEMBER);
                } else {
                    setSubject(Reasons.OTHER);
                }
                break;
            }
            case Reasons.OTHER: {
                if (checked) {
                    setSubject(Reasons.OTHER);
                } else {
                    setSubject(Reasons.BECOME_MEMBER);
                }
            }
            default: {
                // do nothing, control everything with the become member button
            }
        }
    };
    return (
        <div className="checkbox ae-10">
            {_.values(Reasons).map((option, index) => {
                const id = `cfselection-${index}`;

                return (
                    <label
                        key={option}
                        htmlFor={id}
                        style={{ marginRight: "10px" }}
                    >
                        <input
                            name="reason"
                            id={id}
                            checked={subject === option}
                            value={option}
                            type="checkbox"
                            onChange={ev => {
                                setOption({
                                    option,
                                    checked: ev.target.checked
                                });
                            }}
                        />
                        <span className="element" />
                        {ReasonLabel[option]}
                    </label>
                );
            })}
        </div>
    );
};

const TextArea = props => {
    return (
        <label htmlFor={props.name}>
            <textarea
                id={props.name}
                type="text"
                name={props.name}
                placeholder={props.placeholder}
                className={props.className}
                required="required"
            />
        </label>
    );
};

const TextInput = props => {
    return (
        <label htmlFor={props.name}>
            <input
                type={props.type || "text"}
                name={props.name}
                id={props.name}
                placeholder={props.placeholder}
                required="required"
                value={props.value}
            />
        </label>
    );
};

const SelectField = props => {
    const { name, options, placeholder } = props;
    return (
        <label htmlFor={name}>
            <select
                name={name}
                style={{
                    fontWeight: 600,
                    fontSize: "20px"
                }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {_.map(options, option => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </select>
        </label>
    );
};

const LanguageOptions = props => {
    const { name, options, value, onChange } = props;

    return (
        <div
            className="checkbox ae-10"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}
        >
            {options.map((option, index) => {
                const id = `${name}-${index}`;

                return (
                    <label
                        key={option.value}
                        htmlFor={id}
                        style={{
                            marginRight: "10px",
                            position: "relative",
                            cursor: "pointer",
                            flex: "1" // Use flex: 1 to make each option equally fill the available space
                        }}
                    >
                        <input
                            name={name}
                            id={id}
                            value={option.value}
                            type="radio"
                            checked={value === option.value}
                            onChange={() => onChange(option.value)}
                            style={{
                                opacity: 0,
                                position: "absolute"
                            }}
                        />
                        <span
                            className="circle"
                            style={{
                                display: "inline-block",
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                border: `1px solid ${
                                    value === option.value ? "#000" : "#000"
                                }`, // Black border for unselected
                                marginRight: "5px",
                                backgroundColor:
                                    value === option.value
                                        ? "#2894f4"
                                        : "#f0f0f0" // Blue for selected, light grey for unselected
                            }}
                        ></span>
                        {option.label}
                    </label>
                );
            })}
        </div>
    );
};

const ContactForm = props => {
    const { subject, setSubject } = props;
    const actionUrl = `https://formspree.io/${formspreeEndPoint}`;
    const { locale } = React.useContext(LocaleContext);
    const [testbed, setTestbed] = React.useState(false);
    const [preferredLanguage, setPreferredLanguage] = React.useState("");
    return (
        <form
            id="contact-form"
            method="POST"
            action={actionUrl}
            className="wide center"
        >
            <input name="subject" value={subject} type="hidden" />
            <ul className="flex">
                <li className="col-10-12 col-tablet-1-2 col-phablet-1-1">
                    <TextInput name="firstname" placeholder="Firstname" />
                    <TextInput name="surname" placeholder="Surname" />
                    <TextInput
                        name="email"
                        placeholder={translate({
                            label: "Contact_EmailAddress",
                            locale
                        })}
                        type="email"
                    />
                    {false && (
                        <TextInput name="_cc" type="hidden" value={devEmail} />
                    )}
                    <ReasonSelector setSubject={setSubject} subject={subject} />
                    {subject === Reasons.BECOME_MEMBER && (
                        <TextInput name="company" placeholder="Company name" />
                    )}
                    {testbed && (
                        <>
                            <TextInput name="school" placeholder="School" />
                            <TextInput
                                name="classgrade"
                                placeholder="Classgrade"
                            />
                            <SelectField
                                name="test"
                                placeholder="Select a test..."
                                options={[
                                    ..._.map(availableTests, test => ({
                                        value: test.name,
                                        label: test.name
                                    })),
                                    {
                                        value: "Other",
                                        label: "Other, please comment below"
                                    }
                                ]}
                            />
                        </>
                    )}

                    <TextArea
                        className="left ae-6"
                        name="message"
                        placeholder={translate({
                            label: "Contact_Message",
                            locale
                        })}
                    />
                    <div
                        style={{
                            fontWeight: "normal",
                            fontSize: "16px",
                            marginBottom: "1px",
                            color: "#000",
                            textAlign: "left"
                        }}
                    >
                        Preferred correspondence language:
                    </div>

                    <LanguageOptions
                        name="preferredLanguage"
                        options={[
                            { value: Languages.EN, label: "English" },
                            { value: Languages.DE, label: "German" },
                            { value: Languages.FR, label: "French" }
                        ]}
                        value={preferredLanguage}
                        onChange={value => setPreferredLanguage(value)}
                    />
                    <input
                        className="button small white ae-8 margin-top-2 cropBottom"
                        data-success-text={translate({
                            label: "Contact_SendDone",
                            locale
                        })}
                        type="submit"
                        value={translate({
                            label: "Contact_SendMessage",
                            locale
                        })}
                    />
                </li>
            </ul>
        </form>
    );
};

const Contact = props => {
    const [subject, setSubject] = React.useState(DefaultSubject);
    return (
        <Section
            scrollId="contact"
            sectionProps={{
                className: "slide autoHeight whiteSlide half-and-half",
                "data-name": "contact"
            }}
        >
            <Content>
                <div className="container">
                    <div className="wrap" style={{ background: "#fff" }}>
                        <div className="fix-10-12 margin-top-4">
                            <div className="fix-10-12">
                                <h1
                                    className="ae-1 fromLeft header-11 light"
                                    style={{ textAlign: "left" }}
                                >
                                    <I18n label="ContactTitle" />
                                </h1>
                            </div>
                            <div className="ae-2 fromLeft light left">
                                <h2 className="cropBottom template-text-11">
                                    <I18n label="Contact_p1" />
                                </h2>
                            </div>
                            {/* Render the Form component and pass setSubject prop */}
                            <ContactForm
                                setSubject={setSubject}
                                subject={subject}
                            />
                            <div className="ae-2 fromLeft light margin-top-4 left">
                                <h2 className="cropBottom template-text-11">
                                    <I18n label="Contact_p2" />
                                </h2>
                            </div>
                            <div
                                className="left"
                                style={{ fontSize: "large", marginTop: "20px" }}
                            >
                                <a
                                    className="template-text-8 highlighted"
                                    href={`mailto:${contactEmail}`}
                                >
                                    {contactEmail}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="wrap center">
                        <div className="fix-4-12">
                            <div
                                className="embedContainer popupTrigger shadow"
                                data-popup-id="maps"
                                style={{ paddingBottom: "70%" }}
                            >
                                <iframe
                                    allowFullScreen
                                    className="scale"
                                    frameBorder="0"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2745.520808697667!2d6.55799471559304!3d46.51759507912745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c30e347e105ef%3A0x95081486d2abbe03!2sSwiss+EdTech+Collider!5e0!3m2!1sfr!2sus!4v149578892288"
                                    style={{ border: 0 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <div className="background">
                <iframe
                    className="iframeBackground"
                    frameBorder="0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2745.520808697667!2d6.55799471559304!3d46.51759507912745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c30e347e105ef%3A0x95081486d2abbe03!2sSwiss+EdTech+Collider!5e0!3m2!1sfr!2sus!4v149578892288"
                />
            </div>
        </Section>
    );
};

export default Contact;
