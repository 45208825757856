import React, { Component } from "react";
import Typed from "typed.js";

class TypedText extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.element = null;
        this.typed = null;
    }

    componentDidMount() {
        var options = {
            strings: this.props.strings || "",
            typeSpeed: this.props.typeSpeed || 50,
            backDelay: this.props.backDelay || 2500,
            loop: !!this.props.loop,
            shuffle: !!this.props.shuffle
        };

        this.typed = new Typed(this.element, options);
    }

    componentWillUnmount() {
        this.typed && this.typed.destroy();
    }

    init(element) {
        this.element = element;
    }

    render() {
        return <div ref={this.init}></div>;
    }
}

export default TypedText;
