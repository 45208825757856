import Content from "./Content.js";
import React from "react";
import Section from "./Section.js";
import TypedText from "./TypedText.js";
import LocaleContext from "./LocaleContext.js";
import I18n, { translate } from "./I18n.js";

const JoinUs = () => {
    const { locale } = React.useContext(LocaleContext);
    const currentLocale = React.useRef(locale);
    const [reloading, setReloading] = React.useState(false);
    React.useEffect(() => {
        if (reloading) {
            setReloading(false);
        }
        if (currentLocale.current != locale) {
            currentLocale.current = locale;
            setReloading(true);
        }
    }, [locale, reloading, setReloading]);
    const typedTextProps = {
        strings: [translate({ label: "JoinUs_p2", locale })],
        typeSpeed: 50,
        backDelay: 2500,
        loop: true,
        shuffle: false
    };
    if (reloading) {
        return null;
    }
    return (
        <Section
            scrollId="joinus"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "joinus"
            }}
        >
            <Content>
                <div className="container">
                    <div className="wrap">
                        <div
                            className="fix-11-12"
                            style={{ minHeight: "500px" }}
                        >
                            <h1 className="ae-1 header-11 light typedtext">
                                <span>
                                    <I18n label="JoinUs_p1" />
                                </span>
                                <TypedText {...typedTextProps} />
                            </h1>
                            <p>
                                <a className="customHover" href="#contact">
                                    <I18n label="JoinUs_p3" />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </Content>
        </Section>
    );
};

export default JoinUs;
